import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './dashboard';
// import Pages from './pages';
import withMainDashboardLayout from '../../layout/withMainDashboardLayout';
import SurveyDashboard from '../../container/surveys/SurveyDashboard';
import ToolsDashboard from '../../container/tools/ToolsDashboard';
import UserManagement from '../../container/userManagement/UserManagement';
import ToolsHome from '../../container/tools/ToolsHome';
import GuideHandbooks from '../../container/tools/GuideHandbooks';
import ToolsFreeHome from '../../container/tools/ToolsFreeHome';
import ProjectManagement from '../../container/project/ProjectManagement';
import SurveyTemplateEditor from '../../container/surveys/SurveyTemplateEditor';
import { Overview } from '../../container/reports/_/Overview';
import { Metrics } from '../../container/reports/_/Metrics';
import { CustomReports } from '../../container/reports/_/CustomReports';
import { DataService } from '../../config/dataService/dataService';
import PagesRoute from './pages';
import LibrarySection from '../../container/cmsLibrary/LibrarySection ';
import CMSPageContent from '../../container/cmsLibrary/CMSPageContent';
import FreeLibrarySection from '../../container/trainingLibrary/FreeLibrarySection ';
import FreeResources from '../../container/freeResources/FreeResources ';

const ProfileSettings = lazy(() => import('../../container/profile/ProfileSettings'));

const AccountSettings = lazy(() => import('../../container/profile/AccountSettings.js'));
const SubscribeNowTab = lazy(() => import('../../container/profile/subscription/SubscribeNowTab'));
const Reports = lazy(() => import('../../container/reports/reports'));
const SurveyReports = lazy(() => import('../../container/reports/surveyReports/SurveyReports'));

const NotFound = lazy(() => import('../../container/pages/404'));

const mainDashboard = React.memo((props) => {
  const { pathname } = useLocation();
  const organizationalSlug = localStorage.getItem('organizationalSlug');
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    if (pathname.indexOf('main-dashboard') === -1)
    {
      DataService.post("/users/save-visits", { url });
    }  
  }, [pathname]);

  const rearrangeProject = () => {
    props.rearrangeProject();
  }

  const isPaidAccount = localStorage.getItem('is_paid') === 'true';
  
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >

        <Routes>
          <Route index path="main-dashboard" element={<Dashboard {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route index path={`${organizationalSlug}`} element={<Dashboard {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="pages/*" element={<PagesRoute />} />
          <Route path="tools-dashboard/*" element={<ToolsDashboard {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="tools-home/*" element={<ToolsHome {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="free-tools/*" element={<ToolsFreeHome {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="file/getfile/resources/*" element={<FreeResources setOpenKeys={props.setOpenKeys} />} />
          <Route path="projects" element={<ProjectManagement rearrangeProject={rearrangeProject} {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          {isPaidAccount ? (
            <>
              <Route path="subscription/*" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
              <Route path="subscribe-now" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
              <Route path="update-subscription" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
            </>
          ) : (
            <>
              <Route path="subscribe-now" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
              <Route path="update-subscription" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
              <Route path="subscription/subscribe-now" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
              <Route path="subscription/delete-account" element={<SubscribeNowTab {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
            </>
          )}

          <Route path="profile-settings" element={<ProfileSettings {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="account-settings" element={<AccountSettings {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="guides/*" element={<GuideHandbooks {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route path="change-management-library/*" element={<LibrarySection setOpenKeys={props.setOpenKeys}  />} />
          <Route path="ocm-training/*" element={<FreeLibrarySection setOpenKeys={props.setOpenKeys} />} />

          <Route path="free-resources/*" element={<FreeResources setOpenKeys={props.setOpenKeys} />} />
          
          <Route path="content/*" element={<CMSPageContent type={'cms'} setOpenKeys={props.setOpenKeys} />} />
          <Route path="training/content/*" element={<CMSPageContent type={'training'} setOpenKeys={props.setOpenKeys} />} />
          <Route path="user-management" element={<UserManagement rearrangeProject={rearrangeProject} {...props} />} />
          <Route path="surveys" element={<SurveyDashboard {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />} />
          <Route
            path="surveys/:action/:id"
            element={<SurveyTemplateEditor {...props} userTheme={JSON.parse(localStorage.getItem('userTheme'))} />}
          />

          <Route path="reports/activeProject" element={<Reports setOpenKeys={props.setOpenKeys} />} />
          <Route path="reports" element={<Reports setOpenKeys={props.setOpenKeys} />}>
            <Route path="/reports/" element={<Overview setOpenKeys={props.setOpenKeys} />} />
            <Route path="/reports/overview" element={<Overview setOpenKeys={props.setOpenKeys} />} />
            <Route path="/reports/metrics" element={<Metrics setOpenKeys={props.setOpenKeys} />} />
            <Route path="/reports/survey" element={<SurveyReports setOpenKeys={props.setOpenKeys} />} />
            <Route path="/reports/custom-reports" element={<CustomReports setOpenKeys={props.setOpenKeys} />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
    </Suspense>
  );
});

export default withMainDashboardLayout(mainDashboard);
