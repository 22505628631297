import actions from './actions';
import { DataService } from '../../config/dataService/dataService';

const {
  getStakeholderTypeBegin,
  getStakeholderTypeSuccess,
  getStakeholderTypeErr,

  saveStakeholderTypeBegin,
  saveStakeholderTypeSuccess,
  saveStakeholderTypeErr,

  deleteStakeholderTypeBegin,
  deleteStakeholderTypeSuccess,
  deleteStakeholderTypeErr,

  updateStakeholderTypeBegin,
  updateStakeholderTypeSuccess,
  updateStakeholderTypeErr,

  updateGroupNameBegin,
  updateGroupNameSuccess,
  updateGroupNameErr,

  saveChangeTypeBegin,
  saveChangeTypeSuccess,
  saveChangeTypeErr,

  saveChannelBegin,
  saveChannelSuccess,
  saveChannelErr,

  saveObjectiveBegin,
  saveObjectiveSuccess,
  saveObjectiveErr,

  saveSuccessMetricBegin,
  saveSuccessMetricSuccess,
  saveSuccessMetricErr,

  saveCommunicationTypeBegin,
  saveCommunicationTypeSuccess,
  saveCommunicationTypeErr,

  getGroupLevelsBegin,
  getGroupLevelsSuccess,
  getGroupLevelsErr,

  getGroupLevelsOnlyBegin,
  getGroupLevelsOnlySuccess,
  getGroupLevelsOnlyErr,

  getAllJobRolesBegin,
  getAllJobRolesSuccess,
  getAllJobRolesErr,

  getGroupNamesByLevelBegin,
  getGroupNamesByLevelSuccess,
  getGroupNamesByLevelErr,

  getGroupNamesByLevelIdBegin,
  getGroupNamesByLevelIdSuccess,
  getGroupNamesByLevelIdErr,

  getTargetAudienceStatBegin,
  getTargetAudienceStatSuccess,
  getTargetAudienceStatErr,

  getLocationLevelsBegin,
  getLocationLevelsSuccess,
  getLocationLevelsErr,

  getLocationLevelsOnlyBegin,
  getLocationLevelsOnlySuccess,
  getLocationLevelsOnlyErr,

  getLocationNamesByLevelBegin,
  getLocationNamesByLevelSuccess,
  getLocationNamesByLevelErr,
  
  getTypeofChangesBegin,
  getTypeofChangesSuccess,
  getTypeofChangesErr,

  getAudienceListBegin,
  getAudienceListSuccess,
  getAudienceListErr,

  getAudienceDataBegin,
  getAudienceDataSuccess,
  getAudienceDataErr,

  getAccountUserListBegin,
  getAccountUserListSuccess,
  getAccountUserListErr,

  ToolsCopyProjectBegin,
  ToolsCopyProjectSuccess,
  ToolsCopyProjectErr,

  saveAudienceStakeholderBegin,
  saveAudienceStakeholderSuccess,
  saveAudienceStakeholderErr,

  deleteAllAudienceBegin,
  deleteAllAudienceSuccess,
  deleteAllAudienceErr,

  deleteGroupBegin,
  deleteGroupSuccess,
  deleteGroupErr,

  getProfileUserInfoBegin, 
  getProfileUserInfoSuccess, 
  getProfileUserInfoErr,

  saveAudienceBegin,
  saveAudienceSuccess,
  saveAudienceErr,

  getLocationAndGroupLevelsBegin,
  getLocationAndGroupLevelsSuccess,
  getLocationAndGroupLevelsErr,

  addGroupBegin,
  addGroupSuccess,
  addGroupErr
} = actions;


const saveChannels = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveChannelBegin());
    try {
      const response = await DataService.post('/tools/save-communication-channels', values);
      if (response.status !== 200) {
        const messages = dispatch(saveChannelSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveChannelSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveChannelErr(err));
      callback(messages);
    }
  };
};

const saveObjective = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveObjectiveBegin());
    try {
      const response = await DataService.post('/tools/save-communication-objectives', values);
      if (response.status !== 200) {
        const messages = dispatch(saveObjectiveSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveObjectiveSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveObjectiveErr(err));
      callback(messages);
    }
  };
};

const saveSuccessMetric = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveSuccessMetricBegin());
    try {
      const response = await DataService.post('/tools/save-communication-success_metric', values);
      if (response.status !== 200) {
        const messages = dispatch(saveSuccessMetricSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveChannelSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveSuccessMetricErr(err));
      callback(messages);
    }
  };
};

const saveCommunicationType = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveCommunicationTypeBegin());
    try {
      const response = await DataService.post('/tools/save-communication-types', values);
      if (response.status !== 200) {
        const messages = dispatch(saveCommunicationTypeSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveCommunicationTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveCommunicationTypeErr(err));
      callback(messages);
    }
  };
};




const getStakeholderTypes = (values,callback) => {
  return async (dispatch) => {
    dispatch(getStakeholderTypeBegin());
    try {
      const response = await DataService.post('/audiences/get-stakeholder-types', values);
      if (response.status !== 200) {
        const messages = dispatch(getStakeholderTypeErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getStakeholderTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getStakeholderTypeErr(err));
      callback(messages);
    }
  };
};

const getGroupLevels = (values,callback) => {
  return async (dispatch) => {
    dispatch(getGroupLevelsBegin());
    try {
      const response = await DataService.post('/audiences/get-group-levels', values);
      if (response.status !== 200) {
        const messages = dispatch(getGroupLevelsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getGroupLevelsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getGroupLevelsErr(err));
      callback(messages);
    }
  };
};

const getGroupLevelsOnly = (values,callback) => {
  return async (dispatch) => {
    dispatch(getGroupLevelsOnlyBegin());
    try {
      const response = await DataService.post('/audiences/get-group-levels-only', values);
      if (response.status !== 200) {
        const messages = dispatch(getGroupLevelsOnlyErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getGroupLevelsOnlySuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getGroupLevelsOnlyErr(err));
      callback(messages);
    }
  };
};

const getAllJobRoles = (values,callback) => {
  return async (dispatch) => {
    dispatch(getAllJobRolesBegin());
    try {
      const response = await DataService.post('/audiences/get-all-job-roles', values);
      if (response.status !== 200) {
        const messages = dispatch(getAllJobRolesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getAllJobRolesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getAllJobRolesErr(err));
      callback(messages);
    }
  };
};

const getGroupNamesByLevel = (values,callback) => {
  return async (dispatch) => {
    dispatch(getGroupNamesByLevelBegin());
    try {
      const response = await DataService.post('/audiences/get-groups-by-levels', values);
      if (response.status !== 200) {
        const messages = dispatch(getGroupNamesByLevelErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getGroupNamesByLevelSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getGroupNamesByLevelErr(err));
      callback(messages);
    }
  };
};

const getGroupNamesByLevelId = (values,callback) => {
  return async (dispatch) => {
    dispatch(getGroupNamesByLevelIdBegin());
    try {
      const response = await DataService.post('/audiences/get-groups-by-levels-id', values);
      if (response.status !== 200) {
        const messages = dispatch(getGroupNamesByLevelIdErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getGroupNamesByLevelIdSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getGroupNamesByLevelIdErr(err));
      callback(messages);
    }
  };
};

const getTargetAudienceStat = (callback) => {
  return async (dispatch) => {
    dispatch(getTargetAudienceStatBegin());
    try {
      const response = await DataService.get('/audiences/get-target-audience-statistics');
      if (response.status !== 200) {
        const messages = dispatch(getTargetAudienceStatErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getTargetAudienceStatSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getTargetAudienceStatErr(err));
      callback(messages);
    }
  };
};

const getLocationAndGroupLevels = (callback) => {
  return async (dispatch) => {
    dispatch(getLocationAndGroupLevelsBegin());
    try {
      const response = await DataService.get('/audiences/get-locations-groups-levels');
      if (response.status !== 200) {
        const messages = dispatch(getLocationAndGroupLevelsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getLocationAndGroupLevelsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getLocationAndGroupLevelsErr(err));
      callback(messages);
    }
  };
};

const saveGroup = (values, callback) => {
  return async (dispatch) => {
    dispatch(addGroupBegin());
    try {
      const response = await DataService.post('/audiences/add-group', values);
      if (response.status !== 200) {
        const messages = dispatch(addGroupErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(addGroupSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(addGroupErr(err));
      callback(messages);
    }
  };
};

const getLocationLevels = (callback) => {
  return async (dispatch) => {
    dispatch(getLocationLevelsBegin());
    try {
      const response = await DataService.get('/audiences/get-location-levels');
      if (response.status !== 200) {
        const messages = dispatch(getLocationLevelsErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getLocationLevelsSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getLocationLevelsErr(err));
      callback(messages);
    }
  };
};

const getLocationLevelsOnly = (values, callback) => {
  return async (dispatch) => {
    dispatch(getLocationLevelsOnlyBegin());
    try {
      const response = await DataService.post('/audiences/get-location-levels', values);
      if (response.status !== 200) {
        const messages = dispatch(getLocationLevelsOnlyErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getLocationLevelsOnlySuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getLocationLevelsOnlyErr(err));
      callback(messages);
    }
  };
};

const getLocationNamesByLevel = (values,callback) => {
  return async (dispatch) => {
    dispatch(getLocationNamesByLevelBegin());
    try {
      const response = await DataService.post('/audiences/get-locations-by-levels', values);
      if (response.status !== 200) {
        const messages = dispatch(getLocationNamesByLevelErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getLocationNamesByLevelSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getLocationNamesByLevelErr(err));
      callback(messages);
    }
  };
};

const getTypeofChanges = (callback) => {
  return async (dispatch) => {
    dispatch(getTypeofChangesBegin());
    try {
      const response = await DataService.get('/audiences/get-type-changes');
      if (response.status !== 200) {
        const messages = dispatch(getTypeofChangesErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getTypeofChangesSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getTypeofChangesErr(err));
      callback(messages);
    }
  };
};

const saveChangeType = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveChangeTypeBegin());
    try {
      const response = await DataService.post('/audiences/save-change-types', values);
      if (response.status !== 200) {
        const messages = dispatch(saveChangeTypeErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveChangeTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveChangeTypeErr(err));
      callback(messages);
    }
  };
};

const saveStakeholderType = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveStakeholderTypeBegin());
    try {
      const response = await DataService.post('/audiences/save-stakeholder-types', values);
      if (response.status !== 200) {
        const messages = dispatch(saveStakeholderTypeErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveStakeholderTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveStakeholderTypeErr(err));
      callback(messages);
    }
  };
};

const deleteStakeholderType = (values,callback) => {
  return async (dispatch) => {
    dispatch(deleteStakeholderTypeBegin());
    try {
      const response = await DataService.post('/audiences/delete-stakeholder-types', values);
      if (response.status !== 200) {
        const messages = dispatch(deleteStakeholderTypeErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteStakeholderTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteStakeholderTypeErr(err));
      callback(messages);
    }
  };
};

const updateStakeholderType = (values,callback) => {
  return async (dispatch) => {
    dispatch(updateStakeholderTypeBegin());
    try {
      const response = await DataService.post('/audiences/update-stakeholder-types', values);
      if (response.status !== 200) {
        const messages = dispatch(updateStakeholderTypeErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(updateStakeholderTypeSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(updateStakeholderTypeErr(err));
      callback(messages);
    }
  };
};

const updateGroupName = (values,callback) => {
  return async (dispatch) => {
    dispatch(updateGroupNameBegin());
    try {
      const response = await DataService.post('/audiences/update-group-name', values);
      if (response.status !== 200) {
        const messages = dispatch(updateGroupNameErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(updateGroupNameSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(updateGroupNameErr(err));
      callback(messages);
    }
  };
};

const getAudienceList = (values,callback) => {
  return async (dispatch) => {
    dispatch(getAudienceListBegin());
    try {
      const response = await DataService.post('/audiences/get-audience-list', values);
      if (response.status !== 200) {
        const messages = dispatch(getAudienceListErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getAudienceListSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getAudienceListErr(err));
      callback(messages);
    }
  };
};

const getAudienceData = (values,callback) => {
  return async (dispatch) => {
    dispatch(getAudienceDataBegin());
    try {
      const response = await DataService.post('/audiences/get-audiences-data', values);
      if (response.status !== 200) {
        const messages = dispatch(getAudienceDataErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getAudienceDataSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getAudienceDataErr(err));
      callback(messages);
    }
  };
};

const saveAudienceStakeholder = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveAudienceStakeholderBegin());
    try {
      const response = await DataService.post('/audiences/save-audience-stakeholder', values);
      if (response.status !== 200) {
        const messages = dispatch(saveAudienceStakeholderErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveAudienceStakeholderSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveAudienceStakeholderErr(err));
      callback(messages);
    }
  };
};

const deleteAllAudience = (callback) => {
  return async (dispatch) => {
    dispatch(deleteAllAudienceBegin());
    try {
      const response = await DataService.post('/audiences/delete-all-audiences');
      if (response.status !== 200) {
        const messages = dispatch(deleteAllAudienceErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteAllAudienceSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteAllAudienceErr(err));
      callback(messages);
    }
  };
};

const deleteGroup = (values, callback) => {
  return async (dispatch) => {
    dispatch(deleteGroupBegin());
    try {
      const response = await DataService.post('/audiences/delete-group-level-data', values);
      if (response.status !== 200) {
        const messages = dispatch(deleteGroupErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(deleteGroupSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(deleteGroupErr(err));
      callback(messages);
    }
  };
};

const saveAudience = (values,callback) => {
  return async (dispatch) => {
    dispatch(saveAudienceBegin());
    try {
      const response = await DataService.post('/audiences/save-audience', values);
      if (response.status !== 200) {
        const messages = dispatch(saveAudienceErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(saveAudienceSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(saveAudienceErr(err));
      callback(messages);
    }
  };
};

const getAccountUserList = (values,callback) => {
  return async (dispatch) => {
    dispatch(getAccountUserListBegin());
    try {
      const response = await DataService.post('/users/get-account-user-list', values);
      if (response.status !== 200) {
        const messages = dispatch(getAccountUserListErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getAccountUserListSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getAccountUserListErr(err));
      callback(messages);
    }
  };
};

const getToolsInstaller = (routes, callback) => {
  return async (dispatch) => {
    dispatch(ToolsInstallerBegin());
    try {
      const response = await DataService.post(`/tools/${routes}`, {});
      if (response.status !== 200) {
        const messages = dispatch(ToolsInstallerSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(ToolsInstallerSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(ToolsInstallerErr(err));
      callback(messages);
    }
  };
};

const getToolsProjects = (values, callback) => {
  return async (dispatch) => {
    dispatch(ToolsCopyProjectBegin());
    try {
      const response = await DataService.post(`/tools/get-projects-by-tools`, values);
      if (response.status !== 200) {
        const messages = dispatch(ToolsCopyProjectSuccess(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(ToolsCopyProjectSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(ToolsCopyProjectErr(err));
      callback(messages);
    }
  };
};

const getProfileUserInfo = (callback) => {
  return async (dispatch) => {
    dispatch(getProfileUserInfoBegin());
    try {
      const response = await DataService.get(`/users/get-user-information`);
      if (response.status !== 200) {
        const messages = dispatch(getProfileUserInfoErr(response.data.message));
        callback(messages);
      }
      else if (response.status === 200) {
        dispatch(getProfileUserInfoSuccess(true));
        callback(response);
      }
    } catch (err) {
      const messages = dispatch(getProfileUserInfoErr(err));
      callback(messages);
    }
  };
};

export { 
  getStakeholderTypes,
  getGroupLevels,
  getGroupLevelsOnly,
  getAllJobRoles,
  getAccountUserList,
  getTargetAudienceStat,
  getGroupNamesByLevel,
  getGroupNamesByLevelId,
  getLocationLevels,
  getLocationLevelsOnly,
  getLocationNamesByLevel,
  getTypeofChanges,
  saveStakeholderType,
  updateGroupName,
  getAudienceList,
  getAudienceData,
  getToolsInstaller,
  getToolsProjects,
  saveChangeType,
  saveChannels,
  saveObjective,
  saveSuccessMetric,
  saveCommunicationType,
  saveAudienceStakeholder,
  deleteAllAudience,
  deleteGroup,
  getProfileUserInfo,
  saveAudience,
  getLocationAndGroupLevels,
  deleteStakeholderType,
  updateStakeholderType,
  saveGroup
};
