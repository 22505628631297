import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import styled from "styled-components";

const { Search } = Input;

export const FreeResourceContainer = styled.section`
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    height: 200px;
`;

export const FreeResourceImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 200px;
    font-size: 36px;
    color: #fff;
    font-weight: 700;
`;

export const FreeResourceBackgroundImage = styled.img`
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
`;

export const FreeResourceOverlay = styled.div`
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
`;

export const FreeResourceTitle = styled.h1`
    position: relative;
    font-family: Inter, sans-serif;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    margin-top: 5px;
    width: 100%;
    /* padding: 98px 60px 97px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

export const FreeResourceContentAreaWrapper = styled.div`
    background-color: #fff;
    min-height: 641px;
    margin-top: 15px;
    /* padding: 0px 0px 20px 30px; */
    width: 100%;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

/*
 *  Category
 */
export const FreeResourceMainContentWrapper = styled.section`
    display: flex;
    flex-direction: row;
    line-height: normal;
    border-width: 5px 5px 5px 5px;
    border-style: solid;
    border-color: rgba(122, 198, 255, 0.25);
    min-height: 715px;
    height: 100%;
    margin-bottom: 40px;
    align-items: start;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    border-radius: 10px 0px 0px 0px;
    @media (max-width: 991px) {
        width: 100%;
    }
`;

export const FreeResourceCategoryContainer = styled.main`
    display: flex;
    width: 25%;
    flex-direction: column;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    font-weight: 400;
    /* margin: 0 auto; */
    height: 100%;
    border-right: 5px solid rgba(122, 198, 255, 0.25);
`;

export const FreeResourceContentWrapper = styled.main`
    display: flex;
    width: 75%;
    flex-direction: column;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    font-weight: 400;
    height: 100%;
`;

export const FreeResourceCategoryListWrapper = styled.section`
    display: flex;
    /* margin-top: 13px; */
    width: 100%;
    flex-direction: column;
    .ant-list-items {
        cursor: pointer;
        padding-top: 0;
        li.ant-list-item {
            padding: 10px 16px;
            margin-top: 0;
            &.active {
                padding: 0;
                button {
                    background-color: rgba(122, 198, 255, 0.25);
                    display: flex;
                    width: 100%;
                    padding: 20px 16px 20px 16px;
                    cursor: pointer;
                    span.activeTitle {
                        font-family: Inter, sans-serif;
                        flex-grow: 1;
                        flex-basis: auto;
                        margin: auto 0;
                        font-weight: 500;
                    }
                }
            }
            &:first-child {
                margin-top: 23px;
                border-block-start: 1px solid rgba(5, 5, 5, 0.06);
            }
            &:hover {
               background-color: rgba(191, 191, 191, 0.25);
            }
        }
    }
`;

export const FreeStyledSearch = styled(Search)`
    .ant-input-wrapper {
        border-radius: 5px;
        border: 1px solid rgba(217, 217, 217, 0.85);
    }

    .ant-input {
        font-family: Inter, sans-serif;
        font-size: 14px;
        color: #878788;
    }

    .ant-input-search-button {
        background-color: #fff;
        border-left: 1px solid rgba(217, 217, 217, 0.85);
    }

    @media (max-width: 991px) {
        white-space: initial;
    }
`;

export const FreeStyledIcon = styled(FontAwesomeIcon)`
    width: 34px;
    height: 34px;
    color: var(--Icon-Color---Inactive, #bfbfbf);
    margin: auto 0;
    cursor: pointer;
`;

export const FreeResourceItemWrapper = styled.article`
    display: flex;
    align-items: center;
    gap: 17px;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
`;

export const FreeResourceImage = styled.img`
    aspect-ratio: 1.35;
    object-fit: auto;
    object-position: center;
    width: 100px;
    align-self: stretch;
    max-width: 100%;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
`;

export const FreeResourceSeparator = styled.div`
    aspect-ratio: 0.04;
    object-fit: auto;
    object-position: center;
    width: 1px;
    align-self: stretch;
    margin: auto 0;
    background-color: #d9d9d9;
`;

export const FreeResourceItemTitle = styled.h3`
    font-family: Inter, sans-serif;
    text-decoration-line: underline;
    align-self: stretch;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font-size: 18px;
    color: var(--Text-Color, #404040);
    cursor: pointer;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const FreeResourceListWrapper = styled.section`
    display: flex;
    margin-top: 35px;
    width: 100%;
    flex-direction: column;
    font-size: 20px;
    color: var(--Text-Color, #404040);
    padding: 0 36px;
    @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
    }
`;

export const FreeResourceListTitle = styled.h2`
    display: flex;
    align-items: center;
    font: 700 24px Inter, sans-serif;
    margin-bottom: 10px;
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const FreeResourceDivider = styled.hr`
    background-color: #d9d9d9;
    margin: 24px 0;
    height: 1px;
    border: none;
    border-top: 1px dashed rgba(217, 217, 217, 1);
    @media (max-width: 991px) {
        max-width: 100%;
    }
`;

export const FreeResourceContentContainer = styled.main`
    display: flex;
    max-width: 100%;
    flex-direction: column;
    font-weight: 400;
    padding: 23px 0 21px;
`;

export const FreeResourceTopSection = styled.section`
    align-self: flex-end;
    display: flex;
    margin-right: 25px;
    width: 95%;
    max-width: 100%;
    gap: 20px;
    font-size: 14px;
    color: #878788;
    white-space: nowrap;
    justify-content: space-between;
    @media (max-width: 991px) {
        margin-right: 10px;
        flex-wrap: wrap;
        white-space: initial;
    }
`;

export const FreeResourceSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    @media (max-width: 991px) {
        white-space: initial;
    }
`;

export const FreeResourceEmptyMessage = styled.p`
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: #878788;
  text-align: center;
  margin-top: 20px;
`;

export const FreeResourceScrollableListContainer = styled.div`
    height: 900px;
    overflow-y: auto;
    border-radius: 4px;
    /* margin-top: 20px; */

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background-color: #FFFFFF;
        border: 1px solid #E0F2FF;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #E0F2FF;
        border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #7AC6FF;
    }
    .ant-list-items {
        width: 100%;
        li.ant-list-item {
            cursor: pointer;
            .ant-list-item-action {
                display: none;
            }
            &:hover {
                .ant-list-item-action {
                    display: inline-flex;
                }
            }
            &:focus {
                .ant-list-item-action {
                    display: inline-flex;
                }
            }
        }
        li.no-border {
            border: 0px solid #eee;
        }
    }
`;

export const FreeResourceStyledLink = styled.span`
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    display: contents;
`;